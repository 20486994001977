<template>
  <b-table
    ref="table"
    :no-provider-sorting="true"
    :items="getBookings"
    :fields="tableColumns"
    :current-page="pagination.currentPage"
    :per-page="pagination.perPage"
    aria-busy="true"
    class="position-relative"
    responsive="lg"
    primary-key="id"
    empty-text="No Business found"
    show-empty
  >
    <template #cell(actions)="{item}">
      <b-dropdown
        v-if="item.attendees[0].attending_status === 'pending' || item.attendees[0].attending_status === 'approved'"
        variant="link"
        no-caret
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>
        <div v-if="item.attendees[0].attending_status === 'pending'">
          <b-dropdown-item @click="approveBooking(session,item)">
            <feather-icon
              icon="ToggleLeftIcon"
            />
            <span> Approve </span>
          </b-dropdown-item>

          <b-dropdown-item @click="rejectBooking(session,item)">
            <feather-icon
              icon="ToggleRightIcon"
            />
            <span> Reject </span>
          </b-dropdown-item>
        </div>

        <b-dropdown-item
          v-if="item.attendees[0].attending_status === 'approved'"
          @click="cancelBooking(session,item)"
        >
          <feather-icon
            icon="ToggleLeftIcon"
          />
          <span> Cancel </span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
import bookingStatus from '@/common/compositions/BookingRequests/bookingStatus'

export default {
  name: 'BookingsTable',
  props: {
    getBookings: { type: Function, default: () => null },
    pagination: { type: Object, default: () => {} },
    session: { type: Number, default: null },
  },
  data() {
    return {
      tableColumns: [
        { key: 'id' },
        { key: 'attendees[0].attendee_name', label: 'Main Attendee' },
        { key: 'total_amount', label: 'Count' },
        { key: 'kids_amount', label: 'Kids Count' },
        { key: 'paid_status' },
        { key: 'qrcode' },
        { key: 'attendees[0].attending_status', label: 'status' },
        { key: 'created_at', formatter: v => this.$moment(v).format('YYYY-MM-DD hh:mm A') },
        { key: 'actions' },
      ],
    }
  },
  setup() {
    const { approveBooking, rejectBooking, cancelBooking } = bookingStatus()

    return {
      approveBooking, rejectBooking, cancelBooking,
    }
  },
}
</script>
<style lang="scss">

</style>
