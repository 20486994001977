import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function bookingStatus() {
  const { successfulOperationAlert, showErrors } = handleAlerts()

  const approveBooking = (session, booking) => {
    axiosInstances.activities.post('/internalops/sessions-bookings/approve', {
      session_id: session,
      attendees: booking.attendees.map(attendee => attendee.id),
    }).then(() => {
      successfulOperationAlert('Booking is approved successfully')
      booking.attendees[0].attending_status = 'approved'
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  const rejectBooking = (session, booking) => {
    axiosInstances.activities.post('/internalops/sessions-bookings/reject', {
      session_id: session,
      attendees: booking.attendees.map(attendee => attendee.id),
    }).then(() => {
      successfulOperationAlert('Booking is rejected successfully')
      booking.attendees[0].attending_status = 'rejected'
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  const cancelBooking = (session, booking) => {
    axiosInstances.activities.post('/internalops/sessions-bookings/cancel', {
      session_id: session,
      attendees: booking.attendees.map(attendee => attendee.id),
    }).then(() => {
      successfulOperationAlert('Booking is cancelled successfully')
      booking.attendees[0].attending_status = 'cancelled'
    }).catch(err => {
      showErrors(err.response.data.errors)
    })
  }

  return {
    approveBooking,
    rejectBooking,
    cancelBooking,
  }
}
