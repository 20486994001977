<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4">
          <select-event :event.sync="event" />
        </b-col>
        <b-col md="6">
          <select-session
            :session.sync="session"
            :event="event"
          />
        </b-col>
        <b-col
          md="2"
          class="mt-2 mt-md-1"
        >
          <search-button @refresh="refreshTable" />
        </b-col>
      </b-row>
    </b-card>

    <bookings-table
      ref="parentTable"
      v-bind="{pagination,getBookings,session}"
    />

    <pagination
      :per-page="pagination.perPage"
      :total-rows="pagination.totalRows"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import BookingsTable from '@/common/components/BookingRequests/BookingsTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import SelectEvent from '@/common/components/BookingRequests/SelectEvent.vue'
import SelectSession from '@/common/components/BookingRequests/SelectSession.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'

export default {
  name: 'BookingsList',
  components: {
    BookingsTable, Pagination, SelectEvent, SelectSession, SearchButton,
  },
  data() {
    return {
      event: null,
      session: null,
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: null,
      },
    }
  },
  methods: {
    getBookings() {
      return this.$activities.post('/internalops/sessions-bookings', {
        sessions: [this.session],
      }).then(res => {
        const session = res.data.data[0]
        const bookings = session.booking_details
        return bookings || []
      }).catch(() => [])
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },

}
</script>
<style lang="scss">

</style>
